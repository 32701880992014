import { Button, Grid, Link, makeStyles, TextField } from '@material-ui/core/';
import axios from 'axios';

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import constants from '../../constants';
import { useAuth } from "../../context/auth";

import PasswordReset from '../login/PasswordResetFormDialog';


const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        margin: "0 auto"
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        background: '#C63131',
        "&:hover": {
            background: '#d34a4a',
        }
    },
}));

const FormLogin = () => {
    const { setAuth } = useAuth();
    const { auth } = useAuth();
    const [isLoggedIn, setLoggedIn] = useState(auth ? true : false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [outResult, setOutResult] = useState("");
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [validation, setValidation] = React.useState(
        {
            email: { val: true, text: "" },
            password: { val: true, text: "" },
        }
    )
    function validate() {
        const valid = {
            email: { val: true, text: "" },
            password: { val: true, text: "" },
        };

        const emailRegex = /^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/;
        let isValid = true;
        if (email.length < 3 || !emailRegex.test(email)) {
            valid.email.val = false;
            valid.email.text = "El campo email no cumple con el formato requerido."
            isValid = false;
        }

        if (password.length < 4) {
            valid.password.val = false;
            valid.password.text = "El campo contraseña debe de contener al menos 5 caracteres."
            isValid = false;
        }
        setValidation(valid);

        return isValid;
    }

    



    function requestLogin() {
        const body = {
            "email": email,
            "password": password,
            "fcmToken": localStorage.getItem("fcm"),
            "device": "web"
        };

        console.log(body);
        axios.post(constants.apiBase + "auth/login", body).then(result => {
            if (result.status === 200 && result.headers.authorization != null) {
                if (result.data.data.admin == null) {
                    setOutResult("No tiene los privilegios requeridos");
                } else {
                    setAuth({ account: result.data.data, token: result.headers.authorization });
                    localStorage.removeItem("fcm");
                    setLoggedIn(true);
                }
            }
        }).catch(e => {
            console.error(e);
            setOutResult("Error el correo y/o contraseña son incorrectos");
        });
    }

    if (isLoggedIn) {
        return <Redirect to="/dashboard" />;
    }

    function handlePositiveAction(e) {
        e.preventDefault();
        if (validate()) {
            requestLogin();
        }
    }

    const handlePasswordReset = (e) => {
        setDialogOpen(true);
    }

    const handleClose = (e) => {
        setDialogOpen(false);
    }

    return (
        <form className={classes.form} onSubmit={handlePositiveAction}>
            <TextField
                error={!validation.email.val}
                helperText={validation.email.text}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={e => { setEmail(e.target.value) }} />
            <TextField
                error={!validation.password.val}
                helperText={validation.password.text}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="password"
                value={password}
                onChange={e => { setPassword(e.target.value) }} />

            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handlePositiveAction}>
                Iniciar sesión
            </Button>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Link onClick={handlePasswordReset}>
                        ¿Olvido su contraseña?
                    </Link>
                    <PasswordReset open={dialogOpen} handleClose={handleClose}
                    />
                    <p style={{ color: 'red' }}>{outResult}</p>
                </Grid>
            </Grid>
        </form >
    )
}

export default FormLogin;
