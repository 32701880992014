import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import AdvertisementDataTable from 'components/advertisement/AdvertisementDataTable';
import AdvertisementFormDialog from 'components/advertisement/AdvertisementFormDialog';
import React, { useState } from 'react';
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import { useSnackBar } from "../../context/snackBar";
import Layout from '../common/Layout';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));


const AdvertisementView = React.memo(() => {
    const { auth } = useAuth();
    const [data, setData] = useState([]);
    const { openSnackBar } = useSnackBar();
    const classes = useStyles();
    let [selectedItem, setSelectedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState('create');

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth.token
    }

    function requestLastAdvertisementService() {
        return axios.get(constants.apiBase + "web/advertisements", { headers }).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return null;
        });
    }

    function deleteAdvertisementService(id) {
        return axios.delete(constants.apiBase + "web/advertisements/" + id, { headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Eliminado correctamente' })
            return Promise.resolve();
        }).catch(e => {
            console.error(e);
            return null;
        });
    }

    function requestSaveAdvertisement(url, endDate, imageFile) {
        const _headers = Object.assign({}, headers)
        _headers["Content-Type"] = 'multipart/form-data';

        const formData = new FormData();
        formData.append('url', url);
        formData.append('endDate', endDate);
        formData.append('file', imageFile);

        return axios.post(constants.apiBase + "web/advertisements", formData, { headers: _headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Se proceso lo solicitado correctamente' });
            setDialogOpen(false);
            requestLastAdvertisement();
        }).catch(e => {
            openSnackBar({ open: true, variant: 'error', text: 'No se puedo procesar lo solicitado correctamente' })
            console.error(e);
            return null;
        });
    }

    const handeCreateAction = (e) => {
        setSelectedItem({});
        setDialogAction("create");
        setDialogOpen(true);
    }

    const handleClose = () => {
        setDialogOpen(false);
    }

    function requestUpdateAdvertisement(advertisement, imageFile) {
        const _headers = Object.assign({}, headers)
        _headers["Content-Type"] = 'multipart/form-data';
        const blob = new Blob([JSON.stringify(advertisement)], {
            type: 'application/json'
        });

        const formData = new FormData();
        formData.append('advertisement', blob);
        if (imageFile) formData.append('file', imageFile);

        return axios.put(constants.apiBase + "web/advertisements/" + advertisement.id, formData, { headers: _headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Se proceso lo solicitado correctamente' });
            setDialogOpen(false);
            requestLastAdvertisement();
        }).catch(e => {
            openSnackBar({ open: true, variant: 'error', text: 'No se puedo procesar lo solicitado correctamente' })
            console.error(e);
            return null;
        });
    }

    function saveHandler(url, endDate, imageFile) {
        requestSaveAdvertisement(url, endDate.getTime(), imageFile);
    }

    const handeUpdateAction = (item) => {
        setSelectedItem(item);
        setDialogAction("update");
        setDialogOpen(true);
    }

    const handeDeleteAction = (item) => {
        deleteAdvertisementService(item.id).then(() => requestLastAdvertisement());
    }

    const requestLastAdvertisement = () => {
        requestLastAdvertisementService().then((result) => {
            if (result) {
                result.forEach(element => {
                    element.imgUrl = element.imgUrl ? constants.urlBase + element.imgUrl : '';
                });

                setData(result);
            }
        })
    }

    React.useEffect(() => {
        requestLastAdvertisement();
    }, []);

    return (
        <React.Fragment>
            <Layout headerTitle="Publicidad">
                <Grid item xs={12}>
                    <Button onClick={handeCreateAction} variant="contained" color="primary" style={{ marginBottom: "1rem", padding: ".3rem .6rem", fontSize: "12px" }}>
                        <AddIcon /> Crear nuevo
                    </Button>
                    <Paper className={classes.tableContainer}>
                        <AdvertisementDataTable data={data} clickRow={handeUpdateAction} deleteRow={handeDeleteAction} />
                    </Paper>
                </Grid>
                <AdvertisementFormDialog open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem}
                    updateHandler={requestUpdateAdvertisement} saveHandler={saveHandler} />
            </Layout>
        </React.Fragment>
    );
});

export default AdvertisementView;
