import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import React, { useState } from 'react';
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import IncidenceDTO from '../../model/IncidenceDTO';
import Layout from '../common/Layout';
import DataTable from '../incidence/IncidenceDataTable';
import FormDialog from '../incidence/IncidenceFormDialog';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
}));

const IncidencesView = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { auth } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('create');
  const [selectedItem, setSelectedItem] = useState(null);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth.token
  }

  function requestAll() {
    return axios.get(constants.apiBase + "web/incidences", {
      headers
    }).then(result => {
      return result.data.data;
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

  function requestCategories() {
    return axios.get(constants.apiBase + "web/categories", {
      headers
    }).then(result => {
      return result.data.data;
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

  function requestCreate(item) {
    return axios.post(constants.apiBase + "web/incidences", item, {
      headers
    }).then(result => {
      requestAll().then(result => {
        setData(result);
        setDialogOpen(false);
      });
    }).catch(e => {
      console.error(e);
      return null;
    });
  }

  function requestUpdate(item) {
    return axios.put(constants.apiBase + "web/incidences/" + item.id, item, {
      headers
    }).then(result => {
      requestAll().then(result => {
        setData(result);
        setDialogOpen(false);
      });
    }).catch(e => {
      console.error(e);
    });
  }

  React.useEffect(() => {
    requestAll().then(result => {
      setData(result);
    });
    requestCategories().then(result => {
      setCategories(result);
    })
  }, []);

  const handeUpdateAction = (item) => {
    item.banned = item.bannedAt ? true : false;
    setSelectedItem(item);
    setDialogAction("update");
    setDialogOpen(true);
  }

  const handeCreateAction = (e) => {
    setSelectedItem(IncidenceDTO);
    setDialogAction("create");
    setDialogOpen(true);
  }

  const handleClose = (e) => {
    setDialogOpen(false);
  }

  return (
    <React.Fragment>
      <Layout headerTitle="Incidencias">
        {auth.account.admin.role.id === 1 ?
          <React.Fragment>
            <Grid item xs={12}>
              <Button onClick={handeCreateAction} variant="contained" color="primary" style={{ marginBottom: "1rem", padding: ".3rem .6rem", fontSize: "12px" }}>
                <AddIcon /> Crear nuevo
          </Button>
              <Paper className={classes.tableContainer}>
                <DataTable data={data} clickRow={handeUpdateAction} />
              </Paper>
            </Grid>
            <FormDialog categories={categories} open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem}
              handleCreate={requestCreate} handleUpdate={requestUpdate} />
          </React.Fragment>
          : 'No tiene los privilegios requeridos'}
      </Layout>
    </React.Fragment>
  );
}

export default IncidencesView;