import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getToken } from "firebase/messaging";
import React from "react";
import constants from "../../constants";
import { messaging } from "../../firebase";
import Copyright from "../common/Copyright";
import LoginForm from "../login/LoginForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(" + constants.urlBase + "storage/static/images/login/left.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center center",
  },
  paper: {
    margin: theme.spacing(0, 14),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 4),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 60,
    height: 60,
  },
}));

export default function LoginView() {
  const classes = useStyles();

  const activarMensajes = async () => {

    await Notification.requestPermission().then(async permission => {
      console.log('permiss', permission);
      if (permission === "denied") {
        console.log("No se concedió el permiso. Permitir un nuevo intento.");
        return alert("Por favor activar notificaciones");
      } else if (permission === "default") {
        console.log("La solicitud de permiso fue rechazada.");
        return;
      }
    });

    const token = await getToken(messaging, {
      vapidKey:
        "BNQJdkVqpnc2yi1BFJ4Lss99oGWj5btPXSLw2PNFpW3BH0k42PbfB37TiHFVTWEPMBwHCkwxhuD1V_PRjOrPnuE",
    }).catch((error) =>
      console.error("Tuviste un error al generar el token  " + error)
    );

    if (token) {
      localStorage.setItem("fcm", token);
    } else {
      console.error("No se Genero FCMTOKEN");
    }
    return token;
  };

  React.useEffect(() => {
    activarMensajes();
  }, []);
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        style={{ display: "flex" }}
        square
      >
        <div className={classes.paper}>
          <img
            style={{ width: 180 }}
            src={constants.urlBase + "storage/static/images/login/logo.png"}
            alt=""
          />
          <br />
          <Typography component="h2" variant="h5">
            Inicio de administrador
          </Typography>
          <LoginForm />
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
