import { Button, Grid, Paper, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import FormDialogPuntos from 'components/puntos/FormDialogPuntos';
import PuntosDataTable from 'components/puntos/PuntosDataTable';
import React, { useState } from 'react';
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import { useSnackBar } from "../../context/snackBar";
import Layout from '../common/Layout';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

const PuntoView = React.memo(()=> {
    const { auth } = useAuth();
    const [data, setData] = useState([]);
    const { openSnackBar } = useSnackBar();
    const classes = useStyles();
    let [selectedItem, setSelectedItem] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSector, setSelectedSector] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState('create');
    const [viewPoints, setViewPoints] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth.token
    }

    function requestPuntosService() {
        return axios.get(constants.apiBase + "web/points", { headers }).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return null;
        });
    }

    function requestCategory(){
        return axios.get(constants.apiBase + "web/categories/listForPoints",{headers}).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return [];
        });
    }

    function requestSectorsService() {
        return axios.get(constants.apiBase + "web/sectors", { headers }).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return [];
        });
    }

    function deletePuntoService(id) {
        return axios.delete(constants.apiBase + "web/points/" + id, { headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Eliminado correctamente' })
            return Promise.resolve();
        }).catch(e => {
            console.error(e);
            return null;
        });
    }

    function requestSavePunto(punto) {
        const _headers = Object.assign({}, headers);
        const payload = { ...punto};

        return axios.post(constants.apiBase + "web/points", payload, { headers: _headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Se proceso lo solicitado correctamente' });
            setDialogOpen(false);
            requestPuntos();
        }).catch(e => {
            openSnackBar({ open: true, variant: 'error', text: 'No se puedo procesar lo solicitado correctamente' })
            console.error(e);
            return null;
        });
    }

    const handeCreateAction = (e) => {
        setSelectedItem({});
        setViewPoints(false);
        setDialogAction("create");
        setDialogOpen(true);
    }

    const handleClose = () => {
        setDialogOpen(false);
        setViewPoints(false);
    }

    function requestUpdatePunto(punto) {
        const _headers = Object.assign({}, headers);
        const payload = { ...punto};

        return axios.put(constants.apiBase + "web/points/" + punto.id, payload, { headers: _headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Se proceso lo solicitado correctamente' });
            setDialogOpen(false);
            requestPuntos();
        }).catch(e => {
            openSnackBar({ open: true, variant: 'error', text: 'No se puedo procesar lo solicitado correctamente' })
            console.error(e);
            return null;
        });
    }

    const handeUpdateAction = (item) => {
        setSelectedItem(item);
        setDialogAction("update");
        setDialogOpen(true);
    }

    const handeDeleteAction = (id) => {
        deletePuntoService(id).then(() => requestPuntos());
    }

    const requestPuntos = () => {
        requestPuntosService().then((result) => setData(result));
    }

    const handleClickViewPoints = points => {
        setSelectedItem({ points });
        setViewPoints(true);
        setDialogOpen(true);
        setDialogAction("viewPoints");
    }

    React.useEffect(() => {
        requestPuntos();
        requestCategory().then(result =>{
            setSelectedCategory(result);
        });
        requestSectorsService().then(result => {
            setSelectedSector(result);
        });
    }, []);

    return (
        <React.Fragment>
            <Layout headerTitle="Puntos Municipales">
                <Grid item xs={12}>
                    <Button onClick={handeCreateAction} variant="contained" color="primary" style={{ marginBottom: "1rem", padding: ".3rem .6rem", fontSize: "12px" }}>
                        <AddIcon /> Crear nuevo
                    </Button>
                    <Paper className={classes.tableContainer}>
                        <PuntosDataTable data={data} clickRow={handeUpdateAction} deleteRow={handeDeleteAction} />
                    </Paper>
                </Grid>
                <FormDialogPuntos open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem} sector={selectedSector} category={selectedCategory}
                    updateHandler={requestUpdatePunto} saveHandler={requestSavePunto} viewPoints={viewPoints}/>
            </Layout>
        </React.Fragment>
    );
});

export default PuntoView;