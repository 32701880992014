import { Badge, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';

const headCells = [
  { id: 'firstName', numeric: false, label: 'Nombre' },
  { id: 'lastName', numeric: false, label: 'Apellido' },
  { id: 'email', numeric: false, label: 'Correo' },
  { id: 'role', numeric: false, label: 'Rol' },
  { id: 'agentAcess', numeric: false, label: 'Agente' },
  { id: 'status', numeric: false, label: 'Estado' },
];

function desc(a, b, orderBy) {
  try {
    if (orderBy === "phone" || orderBy === "address") {
      if (b.user[orderBy].toUpperCase() < a.user[orderBy].toUpperCase()) {
        return -1;
      }
      if (b.user[orderBy].toUpperCase() > a.user[orderBy].toUpperCase()) {
        return 1;
      }
    } else {
      if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
        return -1;
      }
      if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
        return 1;
      }
    }
    return 0;
  } catch (e) {
    return 0;
  }
}

const AgentDataTable = (props) => {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleClick = (event, row) => {
    props.clickRow(row);
  };

  return (
    <React.Fragment>
      <Table aria-label="lista agentes">
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align="center"
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(props.data, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow
                style={{ cursor: 'pointer' }}
                  hover
                  onClick={event => handleClick(event, row)}
                  tabIndex={-1}
                  key={row.id}>
                  <TableCell component="th" id={row.id} scope="row" align="center">{row.firstName}</TableCell>
                  <TableCell align="center">{row.lastName}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.admin.role.title}</TableCell>
                  <TableCell align="center">{row.user ? (row.user.type === 'AGENT' ? 'Si' : 'No') : 'No'}</TableCell>
                  <TableCell align="center">
                    <Badge badgeContent={row.bannedAt ? 'Inactivo' : 'Activo'} color={row.bannedAt ? 'secondary' : 'primary'} />
                  </TableCell>

                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

export default AgentDataTable;