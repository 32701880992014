import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMZ_HW2oy2ms2KdzP3I-T1sBgvtItKc-Q",
  authDomain: "alerta-samegua.firebaseapp.com",
  projectId: "alerta-samegua",
  storageBucket: "alerta-samegua.appspot.com",
  messagingSenderId: "833053187783",
  appId: "1:833053187783:web:41b6866ea5310f93a4a8e2",
  measurementId: "G-0YB12H031T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);