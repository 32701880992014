import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import Account from '../../model/AccountDTO';
import AgentDataTable from '../agent/AgentDataTable';
import FormDialog from '../agent/AgentFormDialog';
import { useSnackBar } from "../../context/snackBar";
import Layout from '../common/Layout';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
}));

const AgentView = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('create');
  const { openSnackBar } = useSnackBar();
  let [selectedItem, setSelectedItem] = useState(null);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth.token
  }

  function requestAllAgents() {
    return axios.get(constants.apiBase + "web/agents", {
      headers
    }).then(result => {
            return result.data.data;
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

  function requestCreateAgent(agent) {
    return axios.post(constants.apiBase + "web/agents", agent, {
      headers
    }).then(result => {
        requestAllAgents().then(result => {
          setData(result);
          setDialogOpen(false);
        });
    }).catch(e => {
      openSnackBar({ open: true, variant: 'error', text: 'El correo y/o telefono ya se encuentra registrado' })
      return null;
    });
  }

  function requestUpdateAgent(agent) {
    return axios.put(constants.apiBase + "web/agents/" + agent.id, agent, {
      headers
    }).then(result => {
      requestAllAgents().then(result => {
        setData(result);
        setDialogOpen(false);
      });
    }).catch(e => {
      console.error(e);
    });
  }

  React.useEffect(() => {
    if (auth.account.admin.role.id === 1 || auth.account.admin.role.id === 2) {
      requestAllAgents().then(result => {
        setData(result);
      });
    }
  }, []);

  const handeUpdateAction = (item) => {
    item.banned = item.bannedAt ? true : false;
    setSelectedItem(item);
    setDialogAction("update");
    setDialogOpen(true);
  }

  const handeCreateAction = (e) => {
    setSelectedItem(Account);
    setDialogAction("create");
    setDialogOpen(true);
  }

  const handleClose = (e) => {
    setDialogOpen(false);
  }

  return (
    <React.Fragment>
      {[1, 2].includes(auth.account.admin.role.id)?
        <Layout headerTitle="Agentes">
          <Grid item xs={12}>
            <Button onClick={handeCreateAction} variant="contained" color="primary" style={{ marginBottom: "1rem", padding: ".3rem .6rem", fontSize: "12px" }}>
              <AddIcon /> Crear nuevo
          </Button>
            <Paper className={classes.tableContainer}>
              <AgentDataTable data={data} clickRow={handeUpdateAction} />
            </Paper>
          </Grid>
          <FormDialog open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem}
            handleCreate={requestCreateAgent} handleUpdate={requestUpdateAgent} />
        </Layout>
        : <Redirect to="/" />}
    </React.Fragment>
  );
}

export default AgentView;