import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import React from 'react';
import { ui } from '../../constants';
import { useAuth } from "../../context/auth";
import { useSideBar } from '../../context/sidebar';
import MenuList from './MenuList';



const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: ui.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const SideBar = React.memo(() => {
  const classes = useStyles();
  const { setSideBar } = useSideBar();
  const { sideBar } = useSideBar();
  const { auth } = useAuth();

  const handleDrawerClose = () => {
    setSideBar(false);
  };

  return (
    <Drawer variant="persistent" classes={{ paper: clsx(classes.drawerPaper, !sideBar && classes.drawerPaperClose) }} open={sideBar}>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <MenuList role={auth.account.admin.role} />
      </List>
    </Drawer>
  );
});

export default SideBar;

