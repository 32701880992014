import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import Account from '../../model/AccountDTO';
import AdminDataTable from '../admin/AdminDataTable';
import AdminFormDialog from '../admin/AdminFormDialog';
import { useSnackBar } from "../../context/snackBar";
import Layout from '../common/Layout';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
}));

const AgentView = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const { auth } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('create');
  const { openSnackBar } = useSnackBar();
  let [selectedItem, setSelectedItem] = useState(null);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth.token
  }

  function requestRoles() {
    return axios.get(constants.apiBase + "web/roles", {
      headers
    }).then(result => {
      return result.data.data;
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

  function requestAll() {
    return axios.get(constants.apiBase + "web/admins", {
      headers
    }).then(result => {
      return result.data.data;
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

  function requestCreate(item) {
    return axios.post(constants.apiBase + "web/admins", item, {
      headers
    }).then(result => {
      requestAll().then(result => {
        setData(result);
        setDialogOpen(false);
        window.location.reload();
      });
    }).catch(e => {
      console.error(e);
      openSnackBar({ open: true, variant: 'error', text: 'El correo ya se encuentra registrado' })
      return null;
    });
  }

  function requestUpdate(item) {
    return axios.put(constants.apiBase + "web/admins/" + item.id, item, {
      headers
    }).then(result => {
      requestAll().then(result => {
        setData(result);
        setDialogOpen(false);
      });
    }).catch(e => {
      console.error(e);
    });
  }

  const handeUpdateAction = (item) => {
    item.banned = item.bannedAt ? true : false;
    item.agentAccess = item.user && item.user.type === 'AGENT' ? true : false;
    setSelectedItem(item);
    setDialogAction("update");
    setDialogOpen(true);
  }

  const handeCreateAction = (e) => {
    setSelectedItem(Account);
    setDialogAction("create");
    setDialogOpen(true);
  }

  const handleClose = (e) => {
    setDialogOpen(false);
    window.location.reload();
  }

  React.useEffect(() => {
    if (auth.account.admin.role.id === 1) {
      requestAll().then(result => {
        setData(result);
      });
      requestRoles().then(result => {
        setRoles(result);
      })
    }
  }, []);

  return (
    <React.Fragment>
      {auth.account.admin.role.id == 1 ?
        <Layout headerTitle="Administradores">
          <Grid item xs={12}>
            <Button onClick={handeCreateAction} variant="contained" color="primary" style={{ marginBottom: "1rem", padding: ".3rem .6rem", fontSize: "12px" }}>
              <AddIcon /> Crear nuevo
          </Button>
            <Paper className={classes.tableContainer}>
              <AdminDataTable data={data} clickRow={handeUpdateAction} />
            </Paper>
          </Grid>
          <AdminFormDialog open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem} roles={roles}
            handleCreate={requestCreate} handleUpdate={requestUpdate} />
        </Layout>
        : <Redirect to="/" />}
    </React.Fragment>
  );
}

export default AgentView;