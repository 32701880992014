import DateFnsUtils from '@date-io/date-fns';
import {
    AppBar,
    Button,
    Dialog, DialogActions, DialogContent,
    FormControlLabel, Grid,
    IconButton,
    makeStyles,
    Slide,
    TextField,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';

function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    dialogContent: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8)
        }
    }
}));

const _valid = {
    url: { val: true, text: "" },
    image: { val: true, text: "" },
    endDate: { val: true, text: "" },
};

export default function AdvertisementFormDialog(props) {
    const [url, setUrl] = React.useState('');
    const [endDate, setEndDate] = React.useState(new Date());
    const [image, setImage] = React.useState('');
    const [imageFile, setImageFile] = React.useState({ width: 0, height: 0 });
    const [data, setData] = React.useState({ ...props.data });
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [validation, setValidation] = React.useState(_valid);

    function onDateEndChange(date) {
        setEndDate(date);
    }

    function isRatioValid() {
        const ratio = image.width / image.height;
        return ratio > 1.6 && ratio < 1.9 ? true : false;
    }

    function validate() {
        const valid = {
            url: { val: true, text: "" },
            image: { val: true, text: "" },
            endDate: { val: true, text: "" },
        };

        valid.url = !validateUrl(url) ? valid.url = { val: false, text: 'La url no tiene formato adecuado, ejemplo: https://www.google.com' } : valid.url;
        valid.endDate = endDate == null ? valid.endDate = { val: false, text: 'La fecha no puede ser vacía' } : valid.endDate;
        valid.image = image != null && !isRatioValid() ? valid.image = { val: false, text: 'La imagen debe de tene el formato 16:9' } : valid.image;
        setValidation(valid);
        return valid.url.val && valid.image.val && valid.endDate.val;
    }

    function onUrlChange(event) {
        setUrl(event.target.value);
    }

    function onImageChange(event) {
        let file = event.target.files[0];
        var image = new Image();
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                image.src = e.target.result;
                setImage(image);
                setImageFile(file);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    function save() {
        if (validate()) {
            if (props.action === 'create') {
                props.saveHandler(url, endDate, imageFile);
            } else {
                props.updateHandler({ id: data.id, url, endDate }, Object.prototype.toString.call(imageFile).includes('File') ? imageFile : null);
            }
        }
    }

    React.useEffect(() => {        
        if(props.action === 'create'){
            setEndDate(new Date());
            setUrl('');
            setImageFile(null);
        }

        if (props.action === 'update') {
            setUrl(props.data.url);
            setEndDate(props.data.endDate);
            setImageFile(null);
        }

        setData({ ...props.data });
        const image = new Image();
        const _src = props.data?.imgUrl;
        if (_src) {
            image.src = _src;
        }
        setImage(image);
    }, [props.data])

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={props.open} fullWidth={true}
                onClose={props.handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {props.action === 'create' ? 'Crear publicidad' : 'Editar publicidad'}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.dialogContent}>
                    <TextField id="link" label="Enlace" placeholder="Ingresa un enlace" error={!validation.url.val} helperText={validation.url.text}
                        fullWidth margin="normal" defaultValue={data ? data.url : ''} InputLabelProps={{ shrink: true }} style={{ marginTop: 0 }} onChange={onUrlChange} />

                    <FormControlLabel style={{ margin: 0, marginBottom: '8px', alignItems: "start", width: '100%' }}
                        control={
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <DateTimePicker fullWidth value={endDate} onChange={onDateEndChange} format="dd/MM/yyyy HH:mm" />
                            </MuiPickersUtilsProvider>
                        }
                        label={<span style={{ marginRight: 16, fontSize: "13px", color: 'rgba(0, 0, 0, 0.54)' }}>Fecha de expiración</span>}
                        labelPlacement="top" />

                    <Grid item xs={12} style={{ marginTop: 8 }}>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel style={{ margin: 0, alignItems: "start" }}
                                control={<input label="Imágen" type="file" accept="image/*" onChange={onImageChange} />}
                                label={<span style={{ marginRight: 16, fontSize: "13px", color: 'rgba(0, 0, 0, 0.54)' }}>Subir imágen</span>}
                                labelPlacement="top" />
                            {props.action === 'create' ? (!validation.image.val ? <p style={{ color: '#f44336', fontSize: '0.75rem' }} className="MuiFormHelperText-root Mui-error">{validation.image.text}</p> : '')
                                : (!validation.image.val ? <p style={{ color: '#f44336', fontSize: '0.75rem' }} className="MuiFormHelperText-root Mui-error">{validation.image.text}</p> : '')}
                        </Grid>

                        <Grid item xs={12}>
                            <img id="imgPreview" style={{ maxHeight: '42vh', maxWidth: '100%', marginTop: 8 }} src={image.src ? image.src : ''} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={save} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}